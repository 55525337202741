/* eslint-disable react/prop-types */
/* eslint-disable prefer-destructuring */
import React from 'react';
import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';
import HourglassEmpty from '@mui/icons-material/HourglassEmpty';
import Done from '@mui/icons-material/Done';
import Error from '@mui/icons-material/Error';
import Cancel from '@mui/icons-material/Cancel';
import ArrowRightAlt from '@mui/icons-material/ArrowRightAlt';
import Mail from '@mui/icons-material/Mail';
import Warning from '@mui/icons-material/Warning';
import Report from '@mui/icons-material/Report';
import Block from '@mui/icons-material/Block';
import Tooltip from '@mui/material/Tooltip';

const MailLogStatus = ({ mailLog }) => {
  let IconComponent;
  let color;
  let tooltipTitle;
  const status = mailLog?.sendGridData?.events[0].status;
  switch (status) {
    case 'DELIVERED':
      IconComponent = Check;
      color = 'success';
      tooltipTitle = 'Enviado';
      break;
    case 'NOT_DELIVERED':
      IconComponent = Clear;
      color = 'warning';
      tooltipTitle = 'No enviado';
      break;
    case 'PROCESSING':
      IconComponent = HourglassEmpty;
      color = 'secondary';
      tooltipTitle = 'Procesando';
      break;
    case 'PROCESSED':
      IconComponent = Done;
      color = 'secondary';
      tooltipTitle = 'Procesado';
      break;
    case 'DEFERRED':
      IconComponent = ArrowRightAlt;
      color = 'secondary';
      tooltipTitle = 'Deferred';
      break;
    case 'BOUNCED':
      IconComponent = Mail;
      color = 'error';
      tooltipTitle = 'Rebote';
      break;
    case 'DROPPED':
      IconComponent = Block;
      color = 'secondary';
      tooltipTitle = 'Botado';
      break;
    case 'OPEN':
      IconComponent = Warning;
      color = 'info';
      tooltipTitle = 'Abierto';
      break;
    case 'CLICK':
      IconComponent = Report;
      color = 'success';
      tooltipTitle = 'Click';
      break;
    case 'SPAM_REPORT':
      IconComponent = Cancel;
      color = 'warning';
      tooltipTitle = 'Spam';
      break;
    case 'UNSUSCRIBE':
    case 'GROUP_UNSUSCRIBE':
    case 'GROUP_RESUSCRIBE':
      IconComponent = Error;
      color = 'error';
      tooltipTitle = 'Unsubscribe';
      break;
    default:
      IconComponent = Error;
      color = 'inherit';
      tooltipTitle = 'Sin eventos';
      break;
  }
  return (
    <Tooltip title={tooltipTitle}>
      <IconComponent color={color} />
    </Tooltip>
  );
};

export default MailLogStatus;
