import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

const ProgressBarCell = ({ value }) => (
  <Grid container alignItems="center" justifyContent="center">
    <LinearProgress
      sx={{ minWidth: 120, minHeight: 8, backgroundColor: '#E1E1E1' }}
      variant="determinate"
      value={value}
    />
    <Typography textAlign="center">{Math.trunc(value)}%</Typography>
  </Grid>
);

ProgressBarCell.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.number.isRequired,
};

export default ProgressBarCell;
