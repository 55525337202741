import React from 'react';
import { formatDayMonthYear, formatMoney } from '@fingo/lib/helpers';
import TooltipTypography from '@fingo/lib/components/typography/TooltipTypography';

const useConciliationMovementsColumns = () => [
  {
    id: 'masterEntity_Name',
    field: 'masterEntity_Name',
    headerName: 'Empresa',
    type: 'string',
    sortable: true,
    filterable: false,
    flex: 1,
    renderCell: (params) => (
      <TooltipTypography
        variant="subtitle1"
      >
        {params.row.masterEntity?.name}
      </TooltipTypography>
    ),
  },
  {
    id: 'conciliationMovementAccount_Name',
    field: 'conciliationMovementAccount_Name',
    headerName: 'Cuenta',
    type: 'string',
    sortable: true,
    filterable: false,
    flex: 0.8,
    valueGetter: ({ row }) => row.conciliationMovementAccount.name,
    renderCell: ({ value }) => (
      <TooltipTypography
        variant="subtitle1"
      >
        {value}
      </TooltipTypography>
    ),
  },
  {
    id: 'description',
    field: 'description',
    headerName: 'Descripción',
    type: 'string',
    sortable: true,
    filterable: false,
    flex: 1.2,
    renderCell: (params) => (
      <TooltipTypography
        variant="subtitle1"
      >
        {params.row.description}
      </TooltipTypography>
    ),
  },
  {
    id: 'debit',
    field: 'debit',
    headerName: 'Monto',
    type: 'number',
    sortable: true,
    filterable: false,
    flex: 1,
    valueGetter: ({ row }) => row.debit.amount,
    renderCell: (params) => (
      <TooltipTypography
        variant="subtitle1"
      >
        {formatMoney(params.row.debit, true)}
      </TooltipTypography>
    ),
  },
  {
    id: 'credit',
    field: 'credit',
    headerName: 'Monto',
    type: 'number',
    sortable: true,
    filterable: false,
    flex: 1,
    valueGetter: ({ row }) => row.credit.amount,
    renderCell: (params) => (
      <TooltipTypography
        variant="subtitle1"
      >
        {formatMoney(params.row.credit, true)}
      </TooltipTypography>
    ),
  },
  {
    id: 'movementDate',
    field: 'movementDate',
    headerName: 'Fecha',
    type: 'date',
    sortable: true,
    filterable: false,
    flex: 1,
    renderCell: (params) => (
      <TooltipTypography
        variant="subtitle1"
      >
        {params.row.movementDate && formatDayMonthYear(params.row.movementDate)}
      </TooltipTypography>
    ),
  },
];

export default useConciliationMovementsColumns;
