import React from 'react';
import { formatDayMonthYear, formatMoney } from '@fingo/lib/helpers';
import TooltipTypography from '@fingo/lib/components/typography/TooltipTypography';

const useNonOperativeMovementColumns = () => [
  {
    id: 'user_LastName',
    field: 'user_LastName',
    headerName: 'Levantado por',
    type: 'string',
    sortable: true,
    filterable: false,
    flex: 1,
    renderCell: (params) => (
      <TooltipTypography
        variant="subtitle1"
      >
        {params.row.user?.completeName}
      </TooltipTypography>
    ),
  },
  {
    id: 'createdAt',
    field: 'createdAt',
    headerName: 'Fecha levantamiento',
    type: 'string',
    sortable: true,
    filterable: false,
    flex: 1,
    renderCell: (params) => (
      <TooltipTypography
        variant="subtitle1"
      >
        {formatDayMonthYear(params.row.createdAt)}
      </TooltipTypography>
    ),
  },
  {
    id: 'movementType_Name',
    field: 'movementType_Name',
    headerName: 'Tipo',
    type: 'string',
    sortable: true,
    filterable: false,
    flex: 1,
    renderCell: (params) => (
      <TooltipTypography
        variant="subtitle1"
      >
        {params.row.movementType.name}
      </TooltipTypography>
    ),
  },
  {
    id: 'description',
    field: 'description',
    headerName: 'Descripción',
    type: 'string',
    sortable: true,
    filterable: false,
    flex: 1,
    renderCell: (params) => (
      <TooltipTypography
        variant="subtitle1"
      >
        {params.row.description}
      </TooltipTypography>
    ),
  },
  {
    id: 'amount',
    field: 'amount',
    headerName: 'Monto',
    type: 'string',
    sortable: false,
    filterable: false,
    flex: 1,
    renderCell: (params) => (
      <TooltipTypography
        variant="subtitle1"
      >
        ${formatMoney(params.row.absoluteAmount)}
      </TooltipTypography>
    ),
  },
];

export default useNonOperativeMovementColumns;
