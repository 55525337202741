import { MasterEntityNameCell, MoneyCell } from '@fingo/lib/components/cells';
import { addMoneyWithCurrency } from '@fingo/lib/helpers';
import useFetchPresignedUrl from '@fingo/lib/hooks/useFetchPresignedUrl';
import { LoadingButton } from '@mui/lab';
import Typography from '@mui/material/Typography';
import React from 'react';

// eslint-disable-next-line react/prop-types
const DownloadFile = ({ row }) => {
  // eslint-disable-next-line react/prop-types
  const [fetch, loading] = useFetchPresignedUrl(row.globalAppId);
  return (
    <LoadingButton
      onClick={fetch}
      loading={loading}
      size="small"
    >
      Aquí
    </LoadingButton>
  );
};

const useSimulationColumns = () => [
  {
    field: 'company',
    sortable: false,
    headerName: 'Cliente',
    flex: 1,
    renderCell: (params) => {
      const masterEntity = params.row.factoringSimulationDetail[0]?.invoice.company.masterEntity;
      return <MasterEntityNameCell masterEntity={masterEntity} showBlacklist />;
    },
  },
  {
    field: 'amount',
    sortable: false,
    headerName: 'Monto',
    width: 200,
    renderCell: (params) => {
      const amount = params.row.factoringSimulationDetail.reduce(
        (prev, curr) => addMoneyWithCurrency(prev, curr.invoice.amountWithIva),
        0,
      );
      return <MoneyCell amount={amount.amount} />;
    },
  },
  {
    field: 'documents',
    sortable: false,
    headerName: 'Número documentos',
    width: 140,
    renderCell: (params) => (
      <Typography>
        {params.row.factoringSimulationDetail.length} {' '}
        documento(s)
      </Typography>
    ),
  },
  {
    field: 'fetchFile',
    sortable: false,
    headerName: 'Descargar',
    width: 80,
    renderCell: DownloadFile,
  },
];

export default useSimulationColumns;
