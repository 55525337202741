import { useEffect } from 'react';
import { useBooleanState } from '@fingo/lib/hooks';

// eslint-disable-next-line react/prop-types
const LoadSrcBefore = ({ src, children }) => {
  const [imageSet, , setTrueImage] = useBooleanState(false);
  useEffect(() => {
    // eslint-disable-next-line no-undef
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setTrueImage();
    };
  }, [setTrueImage, src]);
  if (!imageSet) return null;
  return children;
};

export default LoadSrcBefore;
